export default function ConditionsList() {
  return (
    <div className="sr-only">
      <h4>Conditions with treatment solutions at Charmelle London</h4>
      <ul>
        <li><a href='https://charmelle.london/conditions/nose-to-mouth-lines/'>Nose To Mouth Lines</a></li>
        <li><a href='https://charmelle.london/conditions/forehead-lines/'>Forehead &amp; Frown Lines</a></li>
        <li><a href='https://charmelle.london/conditions/neck-lines/'>Neck Lines</a></li>
        <li><a href='https://charmelle.london/conditions/acne-scarring/'>Acne scarring</a></li>
        <li><a href='https://charmelle.london/conditions/excess-hair/'>Excess Hair</a></li>
        <li><a href='https://charmelle.london/conditions/blackheads/'>Blackheads</a></li>
        <li><a href='https://charmelle.london/conditions/jowls/'>Sagging jowls</a></li>
        <li><a href='https://charmelle.london/conditions/crows-feet/'>Crows feet</a></li>
        <li><a href='https://charmelle.london/conditions/age-spots/'>Age Spots &amp; Pigmentation</a></li>
        <li><a href='https://charmelle.london/conditions/ingrowing-hairs/'>Ingrowing hairs</a></li>
        <li><a href='https://charmelle.london/conditions/laughter-lines/'>Laughter lines</a></li>
        <li><a href='https://charmelle.london/conditions/pigmentation/'>Pigmentation</a></li>
        <li><a href='https://charmelle.london/conditions/spots/'>Spots</a></li>
        <li><a href='https://charmelle.london/conditions/back-acne/'>Back acne</a></li>
        <li><a href='https://charmelle.london/conditions/thin-lips/'>Thin lips</a></li>
        <li><a href='https://charmelle.london/conditions/sun-damage/'>Sun damage</a></li>
        <li><a href='https://charmelle.london/conditions/acne/'>Acne</a></li>
        <li><a href='https://charmelle.london/conditions/unwanted-fat-or-cellulite/'>Unwanted Fat Or Cellulite</a></li>
        <li><a href='https://charmelle.london/conditions/milia/'>Milia</a></li>
        <li><a href='https://charmelle.london/conditions/tired-eyes/'>Tired eyes</a></li>
        <li><a href='https://charmelle.london/conditions/rosacea-and-redness/'>Rosacea &amp; redness</a></li>
        <li><a href='https://charmelle.london/conditions/stretchmarks/'>Stretchmarks</a></li>
      </ul>
    </div>
  );
}
